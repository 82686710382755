<template>
	<Layout>
		<Header :bordered="false" />
		<!-- prettier-ignore -->
		<h3 class="title is-size-4 mt-3 mb-5">{{ $tc('labels.notification', notifications.length) }}</h3>
		<section class="notify">
			<span v-for="n in notifications" :key="n.id">
				<h4 class="notify__section">{{ n.name }}</h4>
				<div class="notify__header">
					<div class="columns is-mobile">
						<span class="column is-6-mobile is-6-tablet is-8-desktop">{{ $tc('fields.type') }}</span>
						<span class="column is-3-mobile is-3-tablet is-2-desktop notify__centered pointer no-select" @click="selectAll(n, 'push')">{{ $t('labels.platform') }}</span>
						<span class="column is-3-mobile is-3-tablet is-2-desktop notify__centered pointer no-select" @click="selectAll(n)">{{ $t('fields.email') }}</span>
					</div>
				</div>
				<article v-for="i in n.notification_templates" :key="i.id" class="notify__item">
					<div class="columns is-mobile">
						<div class="column is-6-mobile is-6-tablet is-8-desktop">
							<h4 class="notify__title">{{ i.title }}</h4>
							<p class="notify__text">{{ i.description }}</p>
						</div>
						<div class="column is-3-mobile is-3-tablet is-2-desktop notify__centered">
							<b-switch v-model="i.push" @input="setConfig(i)"></b-switch>
						</div>
						<div class="column is-3-mobile is-3-tablet is-2-desktop notify__centered">
							<b-switch v-model="i.email" @input="setConfig(i)"></b-switch>
						</div>
					</div>
				</article>
			</span>
		</section>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Api from '@/services/api'
// import Icon from '@/components/Icon'
// import eventHub from '@/services/eventHub'
// import { create, update } from '@/mixins/modal'
import { errorToast } from '@/mixins/toast'
// import { mapGetters } from 'vuex'
// import { mapState } from 'vuex'

export default {
	components: {
		Layout,
		Header
		//'svg-icon': Icon
	},
	data() {
		return {
			notifications: []
		}
	},
	methods: {
		async selectAll(n, type) {
			n.notification_templates.forEach(n => {
				type == 'push' ? n.push = !n.push : n.email = !n.email
				this.setConfig(n)
			})
		},
		async getAllNotifications() {
			try {
				const response = await Api.get('notification/findAllGroups')
				const { status } = response
				if (status === 200) {
					const { data } = response
					this.notifications = data
				}
			} catch (e) {
				console.log(e)
			}
		},
		async setConfig(n) {
			try {
				const response = await Api.put('notification/mode/' + n.id, { push: n.push, email: n.email })
				const { status } = response
				if (status >= 200) {
					// successToast(this.$t('alerts.configure.success', [n.title]))
				} else {
					errorToast(this.$t('alerts.configure.error', [n.title]))
				}
			} catch (e) {
				errorToast(this.$t('alerts.configure.error', [n.title]))
			}
		}
	},
	mounted() {
		this.getAllNotifications()
	}
}
</script>
